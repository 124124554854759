<template>
  <!--begin::Menu wrapper-->
  <div
    id="kt_aside_menu_wrapper"
    ref="scrollElRef"
    class="hover-scroll-overlay-y my-5 my-lg-5"
    data-kt-scroll="true"
    data-kt-scroll-activate="{default: false, lg: true}"
    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
    data-kt-scroll-height="auto"
    data-kt-scroll-offset="0"
    data-kt-scroll-wrappers="#kt_aside_menu"
  >
    <!--begin::Menu-->
    <div
      id="#kt_header_menu"
      class="
        menu
        menu-column
        menu-title-gray-800
        menu-state-title-primary
        menu-state-icon-primary
        menu-state-bullet-primary
        menu-arrow-gray-500
      "
      data-kt-menu="true"
    >
      <template v-for="(menuItem, j) in sortedMenu(userMenu)" :key="j">
        <template
          v-if="
            userMenu[menuItem].name &&
            checkPermission(userMenu[menuItem].code) &&
            userMenu[menuItem].route &&
            !userMenu[menuItem].children
          "
        >
          <div class="menu-item">
            <router-link
              v-slot="{ href, navigate, isActive, isExactActive }"
              :to="userMenu[menuItem].route"
            >
              <a
                :class="[isActive && 'active', isExactActive && 'active']"
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <span
                  v-if="
                    userMenu[menuItem].svg_icon || userMenu[menuItem].font_icon
                  "
                  class="menu-icon"
                >
                  <i
                    v-if="asideMenuIcons === 'font'"
                    :class="userMenu[menuItem].font_icon"
                    class="bi fs-3"
                  ></i>
                  <span
                    v-else-if="asideMenuIcons === 'svg'"
                    class="svg-icon svg-icon-2"
                  >
                    <inline-svg :src="userMenu[menuItem].svg_icon" />
                  </span>
                </span>
                <span class="menu-title">{{
                  translate(userMenu[menuItem].name)
                }}</span>
              </a>
            </router-link>
          </div>
        </template>
        <div
          v-if="
            userMenu[menuItem].name &&
            checkPermission(userMenu[menuItem].code) &&
            userMenu[menuItem].children
          "
          :class="{ show: hasActiveChildren(userMenu[menuItem].route) }"
          class="menu-item menu-accordion"
          data-kt-menu-sub="accordion"
          data-kt-menu-trigger="click"
        >
          <span class="menu-link">
            <span
              v-if="userMenu[menuItem].svg_icon || userMenu[menuItem].font_icon"
              class="menu-icon"
            >
              <i
                v-if="asideMenuIcons === 'font'"
                :class="userMenu[menuItem].font_icon"
                class="bi fs-3"
              ></i>
              <span
                v-else-if="asideMenuIcons === 'svg'"
                class="svg-icon svg-icon-2"
              >
                <inline-svg :src="userMenu[menuItem].svg_icon" />
              </span>
            </span>
            <span class="menu-title">{{
              translate(userMenu[menuItem].name)
            }}</span>
            <span class="menu-arrow"></span>
          </span>
          <div
            :class="{ show: hasActiveChildren(userMenu[menuItem].route) }"
            class="menu-sub menu-sub-accordion"
          >
            <template
              v-for="(item2, k) in userMenu[menuItem].children"
              :key="k"
            >
              <div
                v-if="
                  item2.name &&
                  item2.route &&
                  !item2.children &&
                  checkPermission(item2.code)
                "
                class="menu-item"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="item2.route"
                >
                  <a
                    :class="[isActive && 'active', isExactActive && 'active']"
                    :href="href"
                    class="menu-link"
                    @click="navigate"
                  >
                    <span class="menu-bullet">
                      <span class="bullet bullet-dot"></span>
                    </span>
                    <span class="menu-title">{{ translate(item2.name) }}</span>
                  </a>
                </router-link>
              </div>
              <div
                v-if="
                  item2.name && item2.children && checkPermission(item2.code)
                "
                :class="{ show: hasActiveChildren(item2.route) }"
                class="menu-item menu-accordion"
                data-kt-menu-sub="accordion"
                data-kt-menu-trigger="click"
              >
                <span class="menu-link">
                  <span class="menu-bullet">
                    <span class="bullet bullet-dot"></span>
                  </span>
                  <span class="menu-title">{{ translate(item2.name) }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div
                  :class="{ show: hasActiveChildren(item2.route) }"
                  class="menu-sub menu-sub-accordion"
                >
                  <template v-for="(item3, k) in item2.children" :key="k">
                    <div
                      v-if="
                        item3.name && item3.route && checkPermission(item3.code)
                      "
                      class="menu-item"
                    >
                      <router-link
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        :to="item3.route"
                      >
                        <a
                          class="menu-link"
                          :class="[
                            isActive && 'active',
                            isExactActive && 'active',
                          ]"
                          :href="href"
                          @click="navigate"
                        >
                          <span class="menu-bullet">
                            <span class="bullet bullet-dot"></span>
                          </span>
                          <span class="menu-title">{{
                            translate(item3.name)
                          }}</span>
                        </a>
                      </router-link>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <div class="menu-item">
        <div class="menu-content">
          <div class="separator mx-1 my-4"></div>
        </div>
      </div>
    </div>
    <!--end::Menu-->
  </div>
  <!--end::Menu wrapper-->
</template>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
  padding-left: calc(0.75rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
  padding-left: calc(1.5rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}
</style>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { getAllPerm } from "@/core/helpers/helpers-utils";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";

export default defineComponent({
  name: "kt-menu",
  components: {},

  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const menu = ref({});
    const authStore = useAuthenticationStore();
    const userDetail = computed(() => {
      return authStore.user;
    });
    const userMenu = computed(() => {
      return authStore.userMenu;
    });

    const userPermission = computed(() => {
      const list = authStore.userPermission
        .filter((item) => item.permissions.includes("R"))
        .map((item) => item.code);

      return getAllPerm(list);
    });

    const scrollElRef = ref<null | HTMLElement>(null);
    onMounted(async () => {
      await authStore.getUserMenu();
      await authStore.getUserProfile();
      await authStore.getUserInfo();
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const checkPermission = (code) => {
      return userPermission.value.includes(code);
    };

    const sortedMenu = (data) => {
      return Object.keys(data).sort();
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      userDetail,
      userMenu,
      menu,
      checkPermission,
      sortedMenu,
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
</script>
