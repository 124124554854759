
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import { getAllPerm } from "@/core/helpers/helpers-utils";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";

export default defineComponent({
  name: "kt-menu",
  components: {},

  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const store = useStore();
    const menu = ref({});
    const authStore = useAuthenticationStore();
    const userDetail = computed(() => {
      return authStore.user;
    });
    const userMenu = computed(() => {
      return authStore.userMenu;
    });

    const userPermission = computed(() => {
      const list = authStore.userPermission
        .filter((item) => item.permissions.includes("R"))
        .map((item) => item.code);

      return getAllPerm(list);
    });

    const scrollElRef = ref<null | HTMLElement>(null);
    onMounted(async () => {
      await authStore.getUserMenu();
      await authStore.getUserProfile();
      await authStore.getUserInfo();
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();

      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const checkPermission = (code) => {
      return userPermission.value.includes(code);
    };

    const sortedMenu = (data) => {
      return Object.keys(data).sort();
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      userDetail,
      userMenu,
      menu,
      checkPermission,
      sortedMenu,
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
