<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--begin::Notifications-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <div
        class="
          btn btn-icon btn-active-light-primary
          position-relative
          w-30px
          h-30px
          w-md-40px
          h-md-40px
        "
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/gws/notification.svg" />
        </span>
      </div>
      <!--end::Menu-->
    </div>
    <!--end::Notifications-->

    <!--begin::User-->
    <div
      class="d-flex align-items-center ms-1 ms-lg-3 gap-2"
      id="kt_header_user_menu_toggle"
    >
      <!--begin::Menu-->
      <div
        class="cursor-pointer symbol symbol-30px symbol-md-40px"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img :src="userProfile.avatar ? userProfile.avatar : 'media/avatars/blank.png'" alt="userphoto" />
      </div>
      <div>
        <div class="fw-bold">Hi, {{ userDetail.name }}</div>
        <div>{{ userDetail.org_type === 'AP2TKI' ? 'P4MI' : userDetail.org_type }}</div>
      </div>
      <KTUserMenu></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <div
      class="d-flex align-items-center d-lg-none ms-2 me-n3"
      title="Show header menu"
    >
      <div
        class="btn btn-icon btn-active-light-primary"
        id="kt_header_menu_mobile_toggle"
      >
        <span class="svg-icon svg-icon-1">
          <inline-svg src="media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div>
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    KTUserMenu,
  },
  setup() {
    const authStore = useAuthenticationStore();
    const userDetail = computed(() => {
      return authStore.user;
    });
    const userProfile = computed(() => {
      return authStore.userProfile;
    })
    return {
      userDetail,
      userProfile,
    };
  },
});
</script>
