
import { computed, defineComponent } from "vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    KTUserMenu,
  },
  setup() {
    const authStore = useAuthenticationStore();
    const userDetail = computed(() => {
      return authStore.user;
    });
    const userProfile = computed(() => {
      return authStore.userProfile;
    })
    return {
      userDetail,
      userProfile,
    };
  },
});
