
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();
    const authStore = useAuthenticationStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };

    const userDetail = computed(() => {
      return authStore.user;
    });
    const userProfile = computed(() => {
      return authStore.userProfile;
    })
    const handleAccountSettings = () => {
      if (userDetail.value.org_type === "SUPER_ADMIN") {
        router.push({ name: "Superadmin Account Settings" });
      } else if (userDetail.value.org_type === "AGENT_P3MI") {
        router.push({ name: "Agent Account Settings" });
      } else if (userDetail.value.org_type === "MEDICAL") {
        router.push({ name: "Medical Account Settings" });
      } else if (userDetail.value.org_type === "JOB_FAIR") {
        router.push({ name: "Jobfair Account Settings" });
      } else if (userDetail.value.org_type === "COMPANY") {
        router.push({ name: "P3MI Account Settings" });
      } else if (userDetail.value.org_type === "P3MI") {
        router.push({ name: "P3MI Account Settings" });
      } else if (userDetail.value.org_type === "VFS") {
        router.push({ name: "VFS Account Settings" });
      } else if (userDetail.value.org_type === "SYARIKAH") {
        router.push({ name: "Syarikah Account Settings" });
      } else if (userDetail.value.org_type === "VFS") {
        router.push({ name: "VFS Account Settings" });
      } else if (userDetail.value.org_type === "AP2TKI") {
        router.push({ name: "AP2TKI Account Settings"})
      } else if (userDetail.value.org_type === "LSP") {
        router.push({ name: "LSP Account Settings"})
      } else if (userDetail.value.org_type === "BLK") {
        router.push({ name: "BLK Account Settings"})
      } else if (userDetail.value.org_type === "POLRI") {
        router.push({ name: "Polri Account Settings"})
      }
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      userDetail,
      userProfile,
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      handleAccountSettings,
      countries,
    };
  },
});
